<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="plan" :translate-params="{id: plan.id, name: plan.name}">Edit Plan  #%{id} - %{name}</translate>
    </h1>
    <Form :data="plan" @save="onSave" :save_text="$pgettext('form','Save')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/plans/components/Form.vue";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'EditPlan',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchPlan(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'eventTemplate.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('plan', [
      'plan',
    ]),
  },
  methods: {
    ...mapActions('plan', [
      'fetchPlan',
      'updatePlan',
    ]),
    async onSave(data) {
      await this.updatePlan({id: this.plan.id,data: data})
      await this.$router.push({name: 'payments.plan.list'})
      throw new SuccessNotification(this.$pgettext('plan', 'Plan updated!'))
    }
  }
}
</script>